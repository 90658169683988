import { ReactElement, useCallback, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { FormikHelpers } from 'formik';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';

// Components
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import AddFamilyMemberPersonalDetailsForm from 'display-components/add-family-member/personal-details-form';

// Components - Modals
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { FamilyAccountErrorModal } from 'display-components/add-family-member/modals';

// Interfaces and types
import {
    FamilyAccountPersonalDetailsFormValues,
    FamilyAccountAddDependentResponse,
    FamilyAccountPersonalDetailsFormProps
} from 'types/family-management';

// States
import { familyProfileVerifyDependentRoutine } from 'state/family-profile/family-profile.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { lowercaseAndCapitalize } from 'util/cart';

// Styles
import './add-an-adult-page.style.scss';

const AddFamilyMemberPersonalDetailsFormPage = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.AddAnAdultDataQuery;
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const accountType = 'adult';

    const showGenericErrorModal = useCallback(
        (memberName: string, errors: FamilyAccountAddDependentResponse['Errors'], prescriptionNumber: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountErrorModal
                            memberName={memberName}
                            errors={errors}
                            prescriptionNumber={prescriptionNumber}
                            t={t}
                            onClose={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const handleOnSubmit = (
        values: Partial<FamilyAccountPersonalDetailsFormValues>,
        helpers: FormikHelpers<Partial<FamilyAccountPersonalDetailsFormValues>>
    ) => {
        setIsBusy(true);
        dispatch(
            familyProfileVerifyDependentRoutine.trigger({
                dependentData: {
                    FirstName: values.firstName?.trim(),
                    LastName: values.lastName?.trim(),
                    DateOfBirth: values.dateOfBirth,
                    PrescriptionNumber: values.prescriptionNumber,
                    AccountType: values.accountType,
                    PhoneNumber: values.phoneNumber?.replace(/\D/g, '')
                },
                onSuccess: (response: FamilyAccountAddDependentResponse) => {
                    setIsBusy(false);
                    navigate('/secure/profile/family-account/add-an-adult/send-invitation');
                },
                onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                    showGenericErrorModal(
                        values.firstName?.trim() as string,
                        errors,
                        values.prescriptionNumber as string
                    );
                }
            })
        );
    };

    const handleOnCancel = () => {
        navigate('/secure/profile/family-account');
    };

    return (
        <WorkflowLayout
            backgroundImage={data.feathersBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t(`pages.profile.addFamilyMember.eyebrowText.${accountType}`) }}
            anonymousOnly={false}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t(`pages.profile.addFamilyMember.eyebrowText.${accountType}`)}
                title={t('pages.profile.addFamilyMember.personalDetailsTitle')}
            >
                <AddFamilyMemberPersonalDetailsForm
                    state={location.state}
                    isBusy={isBusy}
                    onCancel={handleOnCancel}
                    onSubmit={handleOnSubmit}
                    accountType={
                        lowercaseAndCapitalize(accountType) as FamilyAccountPersonalDetailsFormProps['accountType']
                    }
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default AddFamilyMemberPersonalDetailsFormPage;

export const query = graphql`
    query AddAnAdultData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        feathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
